import React, { useRef, useEffect, useCallback } from "react";

type CameraComponentProps = {
  onCapture: (dataUrl: string) => void;
};

const FRAME_INTERVAL = 2000; // Interval in milliseconds

export const Camera: React.FC<CameraComponentProps> = ({ onCapture }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: "environment" } },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.addEventListener("loadedmetadata", () => {
          videoRef.current?.play();
        });
      }
    } catch (error) {
      console.warn("Back camera not available. Falling back to front camera.");

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current?.play();
          });
        }
      } catch (fallbackError) {
        console.error(
          "Error accessing the front camera as fallback:",
          fallbackError
        );
      }
    }
  };

  const captureFrame = useCallback(() => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(videoRef.current, 0, 0);
      const dataUrl = canvas.toDataURL("image/png");
      onCapture(dataUrl);
    }
  }, [onCapture]);

  useEffect(() => {
    startVideo();
    const intervalId = setInterval(captureFrame, FRAME_INTERVAL);

    const videoElement = videoRef.current;

    return () => {
      clearInterval(intervalId);
      if (videoElement && videoElement.srcObject) {
        (videoElement.srcObject as MediaStream)
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [captureFrame]);

  return (
    <div>
      <video
        ref={videoRef}
        playsInline
        style={{ height: "300px", borderRadius: "12px" }}
      />
    </div>
  );
};
