export const COLORS = {
  primary: "#5664F5",
  primary50: "#c2c8fc",
  secondary: "#1b2151",
  secondary50: "#36394f",
  danger: "#f4585a",
  danger800: "#683030",
  gray: "#adadad",
  gray50: "#f9f9f9",
  gray800: "#6d6d6d",
  green: "#83f785",
};
