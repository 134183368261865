import React from "react";
import { Flex } from "../elements/Flex";
import { COLORS } from "../elements/Theme";

type Props = {
  details: Record<string, string>[];
};

export const ResultsTable = (props: Props) => {
  return (
    <div
      style={{
        overflowY: "auto",
        height: "100%",
        width: "100%",
        maxHeight: "400px",
        padding: "16px 8px",
        borderRadius: "12px",
        backgroundColor: COLORS.gray50,
      }}
    >
      {props.details.map((personData, index) => (
        <Flex column justify="start" align="start" key={index}>
          {Object.entries(personData)
            .filter(([k, v]) => k)
            .map(([k, v], j) => (
              <Flex justify="between" align="center" gap="8px" key={j}>
                <b>{k}:</b>
                <div>{v}</div>
              </Flex>
            ))}
          <div>------------------------------</div>
        </Flex>
      ))}
    </div>
  );
};
