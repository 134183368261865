export function findBestMatch(target: string, candidates: string[]): number {
  const minConsecutive = 5;
  let maxMatchLength = 0;
  let bestMatchIndex = -1;

  for (let index = 0; index < candidates.length; index++) {
    const candidate = candidates[index];
    let maxConsecMatch = 0;

    // Check all possible substrings of length `minConsecutive` or more in `target`
    for (let i = 0; i <= target.length - minConsecutive; i++) {
      for (let len = minConsecutive; i + len <= target.length; len++) {
        const substring = target.slice(i, i + len);
        if (candidate.includes(substring)) {
          maxConsecMatch = Math.max(maxConsecMatch, len);
        } else {
          break; // Stop expanding this substring if no match in candidate
        }
      }
    }

    // Update best match if a better consecutive match is found
    if (maxConsecMatch > maxMatchLength) {
      maxMatchLength = maxConsecMatch;
      bestMatchIndex = index;
    }
  }

  return maxMatchLength >= minConsecutive ? bestMatchIndex : -1;
}

export function findBestMatchesFromList(
  targets: string[],
  candidates: string[]
): number[] {
  // find all rows that have the matches
  return candidates
    .map((candidate, index) => ({ candidate, index }))
    .filter(({ candidate }) =>
      targets.every((target) => candidate.includes(target))
    )
    .map(({ index }) => index);
}
