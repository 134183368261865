import React from "react";
import { ResultsTable } from "./ResultsTable";
import { MainButton } from "../elements/Buttons";
import { COLORS } from "../elements/Theme";

type ModalProps = {
  onClose: () => void;
  details: Record<string, string> | null;
};

export const AccessAuthorizedModal: React.FC<ModalProps> = ({
  onClose,
  details,
}) => {
  if (!details) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        border: `3px solid ${COLORS.green}`,
        width: "80%",
      }}
    >
      <h2>מאושר</h2>
      <strong>לא לשכוח לבקש תעודה מזהה</strong>
      <ResultsTable details={[details]} />

      <MainButton variant="gray" onClick={onClose}>
        Close
      </MainButton>
    </div>
  );
};
