// src/components/NoMatchModal.tsx
import React from "react";
import { Flex } from "../elements/Flex";
import { MainButton } from "../elements/Buttons";
import { COLORS } from "../elements/Theme";

type NoMatchModalProps = {
  onClose: () => void;
  plateNumber: string | null;
};

export const NoMatchModal: React.FC<NoMatchModalProps> = ({
  onClose,
  plateNumber,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        border: `3px solid ${COLORS.danger}`,
        width: "80%",
      }}
    >
      <Flex column justify="center" align="center" gap="10px">
        {plateNumber ? (
          <>
            <h2>אין התאמה</h2>
            <p>{`המספר זהוי ${plateNumber} לא קיים במאגר`}</p>
          </>
        ) : (
          <h2>לא זוהו מספרים</h2>
        )}

        <MainButton onClick={onClose} variant="gray">
          סגור
        </MainButton>
      </Flex>
    </div>
  );
};
